.match-icons-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1px;
  margin-bottom: 1px;
  .faded {
    opacity: 0.5;
  }
}
.vertical-divider {
  height: 80%;
  border-left: 1px solid var(--secondary);
}

.datatable {
  [role="row"] {
    min-height: max-content;
  }
}
.ehcZoR.rdt_TableBody {
  max-height: 100% !important;
}

.matches-header {
  display: flex;
  justify-content: space-between;
  .matches-header__timezone-dropdown {
    display: flex;
    justify-content: space-around;
    max-width: 50%;
    flex: 1;
    align-items: center;
    .matches-header__timezone-dropdown-title {
      white-space: nowrap;
      padding-right: 5px;
      font-size: medium;
    }
  }
}
