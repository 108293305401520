
.drag-item-container-class {
  min-width: 98vw;
  max-width: 98vw;
  width: 98vw;
  td {
    border: 1px solid #dee2e6;
    padding: 0.75rem;
    vertical-align: top;
  }
  .place-td {
    width: 9.5vw;
    min-width: 9.5vw;
    max-width: 9.5vw;
  }
  .name-td {
    width: 66.5vw;
    min-width: 66.5vw;
    max-width: 66.5vw;
  }
  .btns-td {
    width: 19vw;
    min-width: 19vw;
    max-width: 19vw;
  }
}
.drag-item-container-li-class{
  list-style: none;
}
