.upload-image-btn {
  width: 150px !important;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  background-size: cover;
  background-position: 50% 50%;
  &:hover {
    border: 1px solid var(--primary);
  }
}

.upload-image-btn-cropped {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  border-radius: 84px;
  cursor: pointer;
  background-size: cover;
  background-position: 50% 50%;
  &:hover {
    border: 1px solid var(--primary);
  }
}