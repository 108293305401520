.brackets-class {
    * {
        min-height: auto;
        min-width: auto;
    }

    &.bg-theme {
        background-color: #03060d;
        color: #ffffff;
    }

    .bg-hilighted {
        background-color: #2c313a;
    }
    
    .bg-force-hilight {
        background-color: #2c313a !important;
    }

    .bg-hilighted-lost {
        background-color: #ab4a58;
    }

    .bg-lost {
        // opacity: 0.5;
        background-color: #0b0e12;
    }

    .align-items-center {
        align-items: center;
    }

    .w-100 {
        width: 100%;
    }

    .h-100 {
        height: 100%;
    }

    .flex-fill {
        flex: 1 1;
    }

    .seed-container {
        border-radius: 10px;
    }

    .seed-item {
        min-height: 40px;
    }

    .visibility-hidden {
        visibility: hidden !important;
    }

    .margin-bottom-7 {
        margin-bottom: 6px !important;
    }

    .position-relative {
        position: relative;
    }

    .zoom-control-buttons {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .zoom-control-button {
        margin: 20px;
        background-color: #000000;
        border: 1px solid #777777;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
    }
}

div:focus {
    outline: none;
}
.single-bracket+.single-bracket{
    margin-top: 70px;
}